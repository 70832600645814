import React from 'react';
import { Navigate } from 'react-router-dom';

import {
  lazyFullPage,
  lazyHeaderCrate,
  lazyContentCrate,
} from 'shared/app/shell';
import StoreLocator from 'store-locator/app/components/root/store-locator';

const Menu = lazyFullPage(
  () => import(/* webpackChunkName: 'ordering-menu' */ '../components/menu')
);
const Crust = lazyFullPage(
  () =>
    import(
      /* webpackChunkName: 'ordering-crust' */ '../components/shared/crust'
    )
);
const Cart = lazyFullPage(
  () => import(/* webpackChunkName: 'ordering-cart' */ '../components/cart')
);
const ProductDetailsPage = lazyFullPage(
  () =>
    import(
      /* webpackChunkName: 'ordering-pdp' */ '../components/product-details-page'
    )
);
const OrderGuestCheckoutHeader = lazyHeaderCrate(
  () =>
    import(
      /* webpackChunkName: 'order-guest-checkout-header' */ '../components/cart/order-guest-checkout-header'
    )
);
const OrderGuestCheckout = lazyContentCrate(
  () =>
    import(
      /* webpackChunkName: 'order-guest-checkout' */ '../components/cart/order-guest-checkout'
    )
);

const usesCrust = {
  fullWidthBottomDrawer: true,
  crustComponent: Crust,
};

const buildRoutes = () => {
  const routes = {
    // cart guest checkout route has to be first in order to work.
    '/menu/cart/guest/checkout': {
      headerCrate: OrderGuestCheckoutHeader,
      contentCrate: OrderGuestCheckout,
      hideGlobalFooter: true,
    },
    '/menu/store-locator*': {
      fullPageComponent: StoreLocator,
      usesCrateLayout: true,
      usesFullPageFlexLayout: true,
      hideGlobalFooter: true,
    },
    '/menu/product/:productNumber/:formCode': {
      fullPageComponent: ProductDetailsPage,
      showGlobalNavMobileBottomShadow: true,
      ...usesCrust,
    },
    '/menu/product/:productNumber/:formCode/nutrition': {
      fullPageComponent: ProductDetailsPage,
      showGlobalNavMobileBottomShadow: true,
      ...usesCrust,
    },
    '/menu/:menu/:category/:subcategory': {
      fullPageComponent: Menu,
      ...usesCrust,
    },
    '/menu/:menu/:category': {
      fullPageComponent: Menu,
      ...usesCrust,
    },
    '/menu': {
      fullPageComponent: Menu,
      ...usesCrust,
    },
  };

  const orderingRoutes = {
    '/menu/cart': {
      fullPageComponent: Cart,
      usesCrateLayout: true,
      hideGlobalNav: true,
      hideGlobalFooter: true,
    },
    '/menu/previous': {
      fullPageComponent: Menu,
      ...usesCrust,
    },
    '/menu/favorites': {
      fullPageComponent: Menu,
      ...usesCrust,
    },
  };

  // Redirect any non-matches back to root menu, ie: /menu/drinks --> /menu.
  // This is only needed for app.starbucks.com since the server side
  // express route for the core app matches everything.
  // In standalone/www ordering, the server app has explicit
  // route matching which leads to natural 404s.
  const fallbackRoute = {
    '/menu/*': {
      fullPageComponent: () => <Navigate replace to="/menu" />,
    },
  };

  return { ...routes, ...orderingRoutes, ...fallbackRoute };
};

const routes = buildRoutes();

export default routes;
