import baseOptions from './base-options';
import rootReducer from './state/reducers/root-reducer';
import {
  closeStoreDetailsOnBackButtonPress,
  goBackAfterLocationChange,
  updateStoreLocatorUrl,
} from './state/actions/application';
import { fetchLocations } from './state/actions/location';
import {
  shouldCloseStoreDetailsOnBackButtonPress,
  shouldFetchSelector,
  shouldGoBackOnLocationChange,
  shouldUpdateUrlSelector,
} from './state/selectors';
import {
  REQUEST_LOCATIONS,
  REQUEST_NEAREST_LOCATION,
} from './state/actions/types';

export default {
  ...baseOptions,
  reducer: rootReducer,
  effects: [
    {
      selector: shouldCloseStoreDetailsOnBackButtonPress,
      actionCreator: closeStoreDetailsOnBackButtonPress,
    },
    {
      selector: shouldGoBackOnLocationChange,
      actionCreator: goBackAfterLocationChange,
    },
    {
      selector: shouldUpdateUrlSelector,
      actionCreator: updateStoreLocatorUrl,
    },
    {
      selector: shouldFetchSelector,
      actionCreator: fetchLocations,
    },
  ],
  asyncActions: [REQUEST_LOCATIONS, REQUEST_NEAREST_LOCATION],
};
