/* eslint-disable max-params */
import { createSelector } from 'reselect';
import { selectedStoreSelector } from 'shared/app/state/selectors/ordering';
import { selectedOrderPaymentSelector } from 'ordering/app/state/selectors/bottom-sheet';
import { pricingSelector } from './cart';

const SVC = 'SVC';

export const isTippingEligibleSelector = createSelector(
  selectedStoreSelector,
  pricingSelector,
  selectedOrderPaymentSelector,
  (selectedStore, pricing, selectedPayment) => {
    return (
      selectedStore?.store?.ownershipTypeCode === 'CO' &&
      pricing?.summary?.price > 0 &&
      selectedPayment?.orderPaymentType === SVC
    );
  }
);
