import React from 'react';
// import { useIntl } from 'react-intl';

import { FEATURES } from 'shared/universal/optimizely-keys';
import { Feature } from 'shared/app/components/optimizely';

// import messages from './messages';

// PLACEHOLDER PAGE - TO BE IMPLEMENTED LATER
const DeliveryLandingPage = () => {
  return (
    <Feature name={FEATURES.IN_APP_DELIVERY}>
      {(optimizelyEnabled) => {
        return optimizelyEnabled ? (
          // const { formatMessage } = useIntl();
          <div>
            {/* not using the H1 component so that it doesn't steal focus from the toggle at the top of the page */}
            <h1>Today Deserves Delivery</h1>
          </div>
        ) : null;
      }}
    </Feature>
  );
};

export default DeliveryLandingPage;
