import React from 'react';
import { FormattedMessage } from 'react-intl';
import Heading from '@starbucks-web/pattern-library/lib/components/heading/';
import DialogWithMessages from 'shared/app/components/dialog-with-messages';
import messages from '../../messages';

export const NO_AVAILABLE_ITEMS_DIALOG_ID = 'NoAvailableItemsDialogId';

const NoAvailableItemsDialog = () => {
  return (
    <DialogWithMessages
      confirmButtonOnly
      confirmText={<FormattedMessage {...messages.changeOrder} />}
    >
      <Heading className="mb4" id={NO_AVAILABLE_ITEMS_DIALOG_ID} tagName="h2">
        <FormattedMessage {...messages.noAvailableItemsDialogTitle} />
      </Heading>
      <FormattedMessage {...messages.noAvailableItemsDialogMessage} />
    </DialogWithMessages>
  );
};

export default NoAvailableItemsDialog;
