import { values, isEmpty } from 'lodash';
import { createSelector } from 'reselect';
import { configSelector, maximumItemsSelector } from './config';
import {
  signedInSelector,
  hasGuestSessionSelector,
} from 'shared/app/bundles/user';
import { pathnameSelector } from 'shared/app/state/selectors/routes';
import { expectingReloadResponseSelector } from 'shared/app/bundles/svc-cards';
import { storeMenuIsReadySelector } from 'shared/app/bundles/menu';
import { selectedStoreSelector } from 'shared/app/state/selectors/ordering';
import { flattenCartItems } from '../../util/cart-content-util';

export const rawGroupSelector = (state) => state.ordering?.group;
export const slimCartSelector = (state) => state.ordering?.cart?.current;
export const slimPreviousCartSelector = (state) =>
  state.ordering?.cart?.previous;
export const pricingSelector = (state) => state.ordering?.order?.pricing;
export const orderStatusSelector = (state) =>
  state.ordering?.order?.orderStatus;
export const mostRecentOrderStatusSelector = (state) =>
  state.ordering?.order?.previous?.orderStatus;
export const deliveryTypeSelector = (state) =>
  state.ordering?.order?.deliveryType;
export const mostRecentOrderTimeSelector = (state) =>
  state.ordering?.order?.mostRecentOrderTime;
export const displayNotificationSelector = (state) =>
  state.ordering?.notification?.displayNotification;
export const notificationImageSelector = (state) =>
  state.ordering?.notification?.productImage;

export const mostRecentOrderExpirationSelector = createSelector(
  mostRecentOrderTimeSelector,
  configSelector,
  (mostRecentOrderTime, config) =>
    mostRecentOrderTime &&
    mostRecentOrderTime + config?.mostRecentOrderStaleAfter
);

// When "isLoading" is not defined we consider it as not loading
export const pricingLoadingSelector = (state) =>
  state?.ordering?.order?.pricing?.isLoading === true;

const getPopulatedCart = (cartItems) => {
  return cartItems?.map((cartItem) => {
    const { product, sizeCode } = cartItem;
    const size =
      product &&
      (product.sizes || product.forms?.[0].sizes)?.find(
        (_size) => _size.sizeCode === sizeCode
      );

    return {
      size,
      ...cartItem,
    };
  });
};

export const cartSelector = createSelector(slimCartSelector, (cartData) =>
  getPopulatedCart(values(cartData))
);

export const cartItemCountSelector = createSelector(cartSelector, (cart) =>
  cart.reduce((memo, cartItem) => memo + cartItem.quantity, 0)
);

// transform the current cart to match the cart that was passed into the pricing call.
// this is a workaround until the way quantity is handled is refactored.
export const expandedCartSelector = createSelector(
  slimCartSelector,
  (slimCart) => {
    return Object.keys(slimCart).reduce((memo, id) => {
      const item = slimCart[id];
      const { quantity } = item;
      const itemsToAdd = flattenCartItems({ quantity, item, id, itemId: id });
      return [...memo, ...itemsToAdd];
    }, []);
  }
);

export const hasPricingSummarySelector = createSelector(
  pricingSelector,
  cartSelector,
  (pricing, cart) => Boolean(!isEmpty(cart) && pricing?.summary)
);

export const showPricingSummarySelector = createSelector(
  hasPricingSummarySelector,
  signedInSelector,
  hasGuestSessionSelector,
  (hasPricingSummary, signedIn, hasGuestSession) =>
    (signedIn || hasGuestSession) && hasPricingSummary
);

export const hasValidOrderPricingSelector = createSelector(
  pricingSelector,
  (pricing) => pricing && pricing.__typename === 'PricedOrder'
);

export const cartQuantitySelector = createSelector(cartSelector, (cart) =>
  cart.reduce((sum, value) => sum + value.quantity, 0)
);

export const hasMaximumItemsSelector = createSelector(
  maximumItemsSelector,
  cartQuantitySelector,
  (maximumItems, cartQuantity) => cartQuantity >= maximumItems
);

export const orderInProgressSelector = createSelector(
  orderStatusSelector,
  expectingReloadResponseSelector,
  (orderStatus, expectingReloadResponse) => {
    return orderStatus.processing || expectingReloadResponse;
  }
);

export const cartAvailabilityNeedsUpdatingSelector = (state) =>
  state?.ordering?.variables?.cartAvailabilityNeedsToBeUpdated;

export const isCartRouteSelector = createSelector(
  pathnameSelector,
  (pathname) => pathname === '/menu/cart'
);

export const pricingNeedsFetchingSelector = (state) =>
  state?.ordering?.variables?.pricingNeedsToBeFetched;

export const shouldFetchPricingSelector = createSelector(
  signedInSelector,
  hasGuestSessionSelector,
  storeMenuIsReadySelector,
  isCartRouteSelector,
  cartAvailabilityNeedsUpdatingSelector,
  pricingNeedsFetchingSelector,
  /* eslint-disable max-params */
  (
    signedIn,
    hasGuestSession,
    menuReady,
    isCartRoute,
    cartNeedsUpdating,
    pricingNeedsFetching
  ) => {
    return Boolean(
      (signedIn || hasGuestSession) &&
        menuReady &&
        isCartRoute &&
        !cartNeedsUpdating &&
        pricingNeedsFetching
    );
  }
);

export const cartOffersSelector = (state) =>
  state?.ordering?.order?.pricing?.cart?.offers ?? [];

export const rewardTypeCartOffersSelector = createSelector(
  cartOffersSelector,
  (offers) =>
    offers?.filter(
      (offer) => offer.type === 'STAR_REWARD' || offer.type === 'REWARD'
    ) ?? []
);

// tech debt item https://sim.starbucks.com/browse/WG-8159
export const cartOfferAppliedSelector = createSelector(
  cartOffersSelector,
  (offers) => offers?.find((offer) => offer.hasBeenApplied === true) || {}
);

// tech debt item https://sim.starbucks.com/browse/WG-8159
export const appliedCartOffersSelector = (state) =>
  state?.ordering?.order?.appliedOffers ?? [];

export const cartHasOfferAppliedSelector = createSelector(
  appliedCartOffersSelector,
  (appliedOffers) => !isEmpty(appliedOffers)
);

export const appliedStarsCartSelector = createSelector(
  cartHasOfferAppliedSelector,
  appliedCartOffersSelector,
  (cartHasOfferApplied, appliedCartOffers) => {
    if (cartHasOfferApplied) {
      return appliedCartOffers.reduce((totalStars, offer) => {
        totalStars += offer.stars || 0;
        return totalStars;
      }, 0);
    }
    return 0;
  }
);

export const isGuestUserSessionSelector = (state) =>
  !state?.user?.sessionMeta || state?.user?.sessionMeta?.guest;

export const continueAsGuestSelector = (state) =>
  state?.ordering?.order?.continueAsGuest ?? false;

export const storeSupportsGuestCheckoutSelector = createSelector(
  selectedStoreSelector,
  (selectedStore) => {
    const { guestOrdering, availability: orderingAvailability } =
      selectedStore?.store?.mobileOrdering ?? {};
    return orderingAvailability === 'READY' && guestOrdering === true;
  }
);
