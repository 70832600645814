import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import LocatorMap from '../locator-map';

import { isMobileViewportSelector } from 'shared/app/bundles/viewport';

import LocatorControls from '../locator-controls';

import messages from './messages';

export const StoreLocatorHeaderCrate = () => {
  const { formatMessage } = useIntl();
  const isMobileViewport = useSelector(isMobileViewportSelector);
  return (
    <React.Fragment>
      <h1 className="hiddenVisually">{formatMessage(messages.title)}</h1>
      {isMobileViewport && <LocatorControls />}
      <LocatorMap />
    </React.Fragment>
  );
};

export default StoreLocatorHeaderCrate;
