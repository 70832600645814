import { defineMessages } from 'react-intl';

export default defineMessages({
  answerQuestions: {
    id: 'stream.drinkFinder.answerQuestions',
    defaultMessage: 'Answer a few questions to find something new',
    description: 'Heading for Drink Quiz stream card',
  },
  hotDrinkOptionText: {
    id: 'stream.drinkFinder.hotDrinkOptionText',
    defaultMessage: 'Warm up and get going',
    description: 'Text describing "hot" drink option',
  },
  icedDrinkOptionText: {
    id: 'stream.drinkFinder.icedDrinkOptionText',
    defaultMessage: 'Cool off and uplift',
    description: 'Text describing "iced" drink option',
  },
});
