import { every, filter, some } from 'lodash';

export const getOptionPricing = (item, pricingObject) => {
  const childItems = pricingObject?.childItems;
  const matchingItem =
    childItems && childItems.find((childItem) => childItem.sku === item.sku);
  return matchingItem?.price;
};

// eslint-disable-next-line complexity
export const getSelectedOptionDetails = (item, productOptions) => {
  const parentCategory =
    productOptions &&
    productOptions.find(
      (option) => option.categoryNumber === item.categoryKeypath[0]
    );

  const subcategory =
    parentCategory &&
    (parentCategory.children || []).find(
      (child) => child.categoryNumber === item.categoryKeypath[1]
    );

  const subcategoryProduct =
    subcategory &&
    (subcategory.products || []).find(
      (product) => product.productNumber === item.optionProductNumber
    );

  const parentCategoryProduct =
    parentCategory &&
    (parentCategory.products || []).find(
      (product) => product.productNumber === item.optionProductNumber
    );

  const product = subcategoryProduct || parentCategoryProduct;

  const isModifierSizeCode =
    item.size && item.size.sizeCode && item.size.sizeCode !== 'add';

  const value = isModifierSizeCode ? item.size.sizeCode : item.value;

  const name = product?.form?.name;
  const unitOfMeasure = subcategory?.attributes?.unitOfMeasure;

  return { name, unitOfMeasure, value };
};

const getItemAvailability = (item) => item?.isAvailable;

export const allItemsAreUnavailable = (items) =>
  every(items, (item) => getItemAvailability(item) === false);

export const hasUnavailableItems = (items) =>
  some(items, (item) => getItemAvailability(item) !== true);

export const itemsThatAreUnavailable = (items) =>
  filter(items, (item) => getItemAvailability(item) !== true);

export const transformOptionsForEditing = (selectedOptions) => {
  return selectedOptions.map((option) => {
    const {
      categoryKeypath,
      categoryKeyPath,
      formCode,
      optionProductNumber,
      size,
      value,
      exclusiveCategoryNum,
    } = option;
    const getValue = () => {
      if (option?.product?.form?.sizes?.[0]?.sizeCode === 'no') {
        // user has removed a product that is in the default recipe,
        // value is zero despite quantity being one.
        return 0;
      }
      return value || option?.quantity;
    };
    return {
      // graphql provides categoryKeyPath (note the capital P),
      // but most of our references in the PDP use categoryKeypath (lowercase P).
      // This is one spot where we normalize.
      // This will be completely normalized in a followup:
      // WG-10484
      categoryKeypath: categoryKeypath || categoryKeyPath,
      formCode: formCode || option?.product?.form?.formCode,
      optionProductNumber:
        optionProductNumber || option?.product?.productNumber,
      size: size || option?.product?.form?.sizes?.[0],
      value: getValue(),
      ...(exclusiveCategoryNum ? { exclusiveCategoryNum } : {}),
    };
  });
};
