import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Route, Routes } from 'react-router-dom';
import { configSelector } from 'shared/app/shell';

import HeaderCrate from '@starbucks-web/pattern-library/lib/components/header-crate';
import ContentCrate from '@starbucks-web/pattern-library/lib/components/content-crate';
import PreventFocus from '@starbucks-web/pattern-library/lib/components/prevent-focus/';
import { trackStoreFinderView } from '../../state/actions/track-event';

import {
  isContentCratePreventFocusEnabledSelector,
  isHeaderCratePreventFocusEnabledSelector,
  selectedFeaturesStateSelector,
  locationsCountSelector,
} from '../../state/selectors';
import { currentRouteSelector } from 'shared/app/state/selectors/routes';

import StoreLocatorHeaderCrate from './store-locator-header-crate';
import StoreLocatorContentCrate from './store-locator-content-crate';
import LocatorFilterOverlay from '../locator-filter-overlay';
import ExpandedLocationContainer from '../expanded-location-container';
import DeliveryToggle from '../delivery-toggle';
import DeliveryLandingPage from '../delivery-landing-page';

import styles from './styles.cssm';

import messages from './messages';

export const LocatorPage = ({ hasGlobalNav }) => {
  const isContentCratePreventFocusEnabled = useSelector(
    isContentCratePreventFocusEnabledSelector
  );
  const isHeaderCratePreventFocusEnabled = useSelector(
    isHeaderCratePreventFocusEnabledSelector
  );
  const locationsCount = useSelector(locationsCountSelector);
  const selectedFeatures = useSelector(selectedFeaturesStateSelector);
  const location = useSelector(currentRouteSelector);

  const config = useSelector(configSelector);
  const enableInAppDelivery = config.enableInAppDelivery;

  const { formatMessage } = useIntl();

  const locationsCrateClass = classNames(
    'height-100 lg-ml0',
    styles.locationsCrate
  );

  const locatorOverlaysClass = classNames(styles.overlayShadow, {
    [styles.globalNavOffset]: hasGlobalNav,
  });

  useEffect(() => {
    if (locationsCount === 0) {
      return;
    }
    trackStoreFinderView({
      // eslint-disable-next-line camelcase
      store_finder_filter_type: selectedFeatures,
      // eslint-disable-next-line camelcase
      count_of_stores_shown: locationsCount,
    });
  }, [locationsCount]);

  return (
    <div className={styles.base}>
      <Helmet
        meta={[
          {
            name: 'description',
            content: formatMessage(messages.description),
          },
        ]}
        title={formatMessage(messages.title)}
      />

      <PreventFocus
        enabled={Boolean(isHeaderCratePreventFocusEnabled)}
        wrapWithDiv={false}
      >
        <HeaderCrate className={styles.mapCrate}>
          <Routes location={location}>
            <Route path="/menu?/store-locator">
              {enableInAppDelivery ? (
                <Route element={<DeliveryLandingPage />} path="delivery" />
              ) : null}
              <Route element={<StoreLocatorHeaderCrate />} path="" />
              <Route element={<StoreLocatorHeaderCrate />} path="*" />
            </Route>
          </Routes>
        </HeaderCrate>
      </PreventFocus>

      <PreventFocus
        enabled={Boolean(isContentCratePreventFocusEnabled)}
        wrapWithDiv={false}
      >
        <ContentCrate className={locationsCrateClass}>
          {/* Having the toggle out here at the top level let's us decide what content to show
    based on the current route, while the toggle itself remains on the page */}
          {enableInAppDelivery ? <DeliveryToggle /> : null}
          <Routes location={location}>
            <Route path="/menu?/store-locator">
              {enableInAppDelivery ? (
                <Route element={<DeliveryLandingPage />} path="delivery" />
              ) : null}
              <Route element={<StoreLocatorContentCrate />} path="" />
              <Route element={<StoreLocatorContentCrate />} path="*" />
            </Route>
          </Routes>
        </ContentCrate>
      </PreventFocus>

      <ExpandedLocationContainer className={locatorOverlaysClass} />
      <LocatorFilterOverlay className={locatorOverlaysClass} />
    </div>
  );
};

export default LocatorPage;
