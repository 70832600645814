import { trackEvent, trackPageView } from 'shared/app/utils/uo-tracking';

/**
 * @param {{menu_product_id: string, product_is_modified: boolean, product_add_source: string}} eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackMOPProductAdd = (eventAttributes) =>
  trackEvent('MOP_PRODUCT_ADD', eventAttributes);

/**
 * @returns {Promise<void>} The event has been sent.
 */
export const trackMOPMenuView = () => trackPageView('MOP_MENU_VIEW');
/**
 * @param {{ menu_heirarchy_name: string }} eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackMOPMenuCategoryView = (eventAttributes) =>
  trackPageView('MOP_MENU_CATEGORY_VIEW', eventAttributes);

/**
 * @param {{menu_heirarchy_name: string}} eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
// TODO: This view is not relevant for web.
export const trackMOPMenuTypeView = (eventAttributes) =>
  trackPageView('MOP_MENU_TYPE_VIEW', eventAttributes);

/**
 * @returns {Promise<void>} The event has been sent.
 */
export const trackMOPFeaturedView = () => trackPageView('MOP_FEATURED_VIEW');

/**
 * @param {{content_state: string}} eventAttributes,
 * @returns {Promise<void>} The event has been sent.
 */
export const trackMOPPreviousView = (eventAttributes) =>
  trackPageView('MOP_PREVIOUS_VIEW', eventAttributes);

/**
 * @param {{content_state: string}} eventAttributes,
 * @returns {Promise<void>} The event has been sent.
 */
export const trackMOPFavoritesView = (eventAttributes) =>
  trackPageView('MOP_FAVORITES_VIEW', eventAttributes);

/**
 * @param {{menu_product_id: string}} eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackMOPProductDetailsView = (eventAttributes) =>
  trackPageView('MOP_PRODUCT_DETAILS_VIEW', eventAttributes);

/**
 * @param {{content_state: string, product_is_unavailable: boolean, customer_type: string}} eventAttributes,
 * @returns {Promise<void>} The event has been sent.
 */
export const trackMOPCartView = (eventAttributes) =>
  trackPageView('MOP_CART_VIEW', eventAttributes);

/**
 * @returns {Promise<void>} The event has been sent.
 */
export const trackMOPApplyRewards = () => trackEvent('MOP_APPLY_REWARDS');

/**
 * @returns {Promise<void>} The event has been sent.
 */
export const trackMOPInStorePickupClick = () =>
  trackEvent('MOP_IN_STORE_PICKUP_CLICK');

/**
 * @returns {Promise<void>} The event has been sent.
 */
export const trackMOPDriveThruPickupClick = () =>
  trackEvent('MOP_DRIVE_THRU_PICKUP_CLICK');

/**
 * @param {{menu_product_id: string, recommendation_carousel_rank: number}} eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackMOPRecommendationsClick = (eventAttributes) =>
  trackEvent('MOP_RECOMMENDATIONS_CLICK', eventAttributes);

/**
 * @param {{new_product_quantity: string}} eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackMOPProductQuantityIncrease = (eventAttributes) =>
  trackEvent('MOP_PRODUCT_QUANTITY_INCREASE', eventAttributes);

/**
 * @param {{new_product_quantity: string}} eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackMOPProductQuantityDecrease = (eventAttributes) =>
  trackEvent('MOP_PRODUCT_QUANTITY_DECREASE', eventAttributes);

/**
 * @param {{insufficient_funds: boolean}} eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackMOPCheckoutClick = (eventAttributes) =>
  trackEvent('MOP_CHECKOUT_CLICK', eventAttributes);

/**
 * @returns {Promise<void>} The event has been sent.
 */
export const trackMOPInsufficientFundsError = () =>
  trackEvent('MOP_INSUFFICIENT_FUNDS_ERROR');

/**
 * @returns {Promise<void>} The event has been sent.
 */
export const trackMOPOrderError = () => trackEvent('MOP_ORDER_ERROR');

/**
 * @param {{payment_method_type: string, order_id: string}} eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackMOPOrderSuccess = (eventAttributes) =>
  trackEvent('MOP_ORDER_SUCCESS', eventAttributes);

/**
 * @returns {Promise<void>} The event has been sent.
 */
export const trackMOPTippingSuccess = () => trackEvent('MOP_TIPPING_SUCCESS');
