/* eslint-disable max-statements */
/* eslint-disable max-params */

import { difference } from 'lodash';
import { createSelector } from 'reselect';

import { geolocationCoordsSelector } from 'shared/app/bundles/geolocation';

import { configSelector } from './config';
import { selectedFeaturesStateSelector } from './selected-features';
import { storeLocatorSelectorWithFallback } from './util';

const ORCHESTRA_SEARCH_RADIUS_LIMIT = 50;

export const locationStateSelector = createSelector(
  storeLocatorSelectorWithFallback,
  ({ locationState }) => locationState || {}
);

export const isGeolocationSearchSelector = createSelector(
  locationStateSelector,
  (locState) => locState.geolocationSearch
);

export const locationsSelector = createSelector(
  locationStateSelector,
  ({ locations }) => locations || []
);

export const placeNameSelector = createSelector(
  locationStateSelector,
  (locState) => locState.placeName
);

export const filteredLocationsSelector = createSelector(
  locationsSelector,
  selectedFeaturesStateSelector,
  (locations, selectedFeatures) => {
    const storesInSearchRadius = locations.filter((location) => {
      if (location.distance > ORCHESTRA_SEARCH_RADIUS_LIMIT) return false;
      return true;
    });

    if (selectedFeatures.length === 0) {
      return storesInSearchRadius;
    }

    const filteredLocations = storesInSearchRadius.filter((location) => {
      const storeAmenitiesCodes = location.store.amenities.map(
        (storeAmenity) => storeAmenity.code
      );

      // GC, 24h and Open Now Amenity codes are not included by Orchestra
      if (location.store.mobileOrdering.guestOrdering)
        storeAmenitiesCodes.push('GC');
      if (location.store.open) storeAmenitiesCodes.push('ON');
      if (location.store.isOpen24Hours) storeAmenitiesCodes.push('hrs24');

      return difference(selectedFeatures, storeAmenitiesCodes).length === 0;
    });

    return filteredLocations;
  }
);

// whether or not we have any locations
export const hasLocationsSelector = createSelector(
  filteredLocationsSelector,
  (locations) => Boolean(locations.length)
);

export const locationsToDisplaySelector = createSelector(
  filteredLocationsSelector,
  (locations) => locations
);

export const haslocationsToDisplaySelector = createSelector(
  locationsToDisplaySelector,
  (locations) => locations?.length
);

export const locationsCountSelector = createSelector(
  locationsToDisplaySelector,
  (locations) => locations.length || 0
);

export const locationsLoadingSelector = createSelector(
  locationStateSelector,
  (locState) => Boolean(locState.loading)
);

export const locationQuerySelector = createSelector(
  locationStateSelector,
  (locState) => locState.locationQuery
);

export const locationQueryUrlSelector = createSelector(
  locationStateSelector,
  (locState) => locState.locationQueryUrl
);

export const resultCoordinatesSelector = createSelector(
  locationStateSelector,
  (locState) => locState.resultCoordinates
);

export const placeNameNotFoundSelector = createSelector(
  locationStateSelector,
  (locState) => locState.placeNameNotFound
);

export const selectedStoreNumberSelector = createSelector(
  locationStateSelector,
  (locState) => locState.selectedStoreNumber
);

export const shouldScrollSelector = createSelector(
  locationStateSelector,
  (locState) => locState.shouldScroll
);

export const highlightedStoreNumberSelector = createSelector(
  locationStateSelector,
  (locState) => locState.highlightedStoreNumber
);

export const fetchAtLocationSelector = createSelector(
  locationStateSelector,
  (locState) => locState.fetchAtLocation
);

export const hasNotFoundErrorSelector = createSelector(
  locationStateSelector,
  (locationState) => {
    return Boolean(locationState.error && locationState.error.status === 404);
  }
);

export const showNoStoresCardSelector = createSelector(
  locationStateSelector,
  hasLocationsSelector,
  locationsLoadingSelector,
  haslocationsToDisplaySelector,
  (locationState, hasLocations, loading, hasSelectableLocations) => {
    return Boolean(
      !locationState.error &&
        (!hasLocations || !hasSelectableLocations) &&
        !loading &&
        locationState.locationQuery !== null
    );
  }
);

// the pan threshold is adjusted for dense areas
// so we need to select this dynamically
export const panThresholdSelector = createSelector(
  locationStateSelector,
  configSelector,
  (locationState, config) => {
    const { panThreshold } = config;
    if (locationState.moreResultsAvailable) {
      return panThreshold * 0.5;
    }
    return panThreshold;
  }
);

export const userGeolocationSelector = createSelector(
  geolocationCoordsSelector,
  configSelector,
  (geolocation, config) => {
    if (!geolocation) {
      return null;
    }
    const { latitude, longitude, accuracy } = geolocation;
    return {
      lat: latitude,
      lng: longitude,
      accuracy,
      withinThreshold: accuracy <= config.geolocationThreshold,
    };
  }
);

// get info object that contains location summary of all the
// locations we have currently
export const coordinateBoundsForLocationsSelector = createSelector(
  filteredLocationsSelector,
  userGeolocationSelector,
  isGeolocationSearchSelector,
  (locations, geolocation, geolocationSearch) => {
    if (!locations.length) {
      return null;
    }

    const result = {
      maxLat: null,
      minLat: null,
      maxLng: null,
      minLng: null,
      centerLat: null,
      centerLng: null,
    };

    let checkedLocations = locations;

    // For geolocation searches, fit bounds to the first three results (results are in order
    // of proximity to the user), and include user's geolocation in bounds.

    if (geolocationSearch) {
      checkedLocations = locations.slice(0, 3);
      checkedLocations.push({
        recommendationReason: 'NEARBY',
        store: {
          coordinates: {
            latitude: geolocation.lat,
            longitude: geolocation.lng,
          },
        },
      });
    }

    checkedLocations.forEach((location) => {
      // Excluding Favorites and Previous stores
      // as they may encompass stores from different cities,
      // which could potentially lead to discrepancies in the coordinate bounds result.
      if (location.distance > ORCHESTRA_SEARCH_RADIUS_LIMIT) return null;

      const { longitude, latitude } = location.store.coordinates;

      if (result.maxLng === null) {
        result.maxLng = longitude;
        result.minLng = longitude;
        result.maxLat = latitude;
        result.minLat = latitude;
      }
      if (latitude > result.maxLat) {
        result.maxLat = latitude;
      }
      if (latitude < result.minLat) {
        result.minLat = latitude;
      }
      if (longitude > result.maxLng) {
        result.maxLng = longitude;
      }
      if (longitude < result.minLng) {
        result.minLng = longitude;
      }
    });

    result.centerLat = (result.maxLat - result.minLat) / 2 + result.minLat;
    result.centerLng = (result.maxLng - result.minLng) / 2 + result.minLng;

    return result;
  }
);
