const currentYear = new Date().getFullYear();

export const seasonConfig = {
  autumnOne: {
    start: new Date(`${currentYear}-08-22T00:00:00`),
    end: new Date(`${currentYear}-09-15T23:59:59`),
    emoji: {
      morning: '🍂',
      afternoon: '☕️',
      evening: '🎃',
    },
  },
  autumnTwo: {
    start: new Date(`${currentYear}-09-16T00:00:00`),
    end: new Date(`${currentYear}-11-01T23:59:59`),
    emoji: {
      morning: '🍂',
      afternoon: '☕️',
      evening: '🧣',
    },
  },
  holiday: {
    start: new Date(`${currentYear}-11-02T00:00:00`),
    end: new Date(`${currentYear}-12-31T23:59:59`),
    emoji: {
      morning: '❤️',
      afternoon: '✨',
      evening: '🎁',
    },
  },
  spring: {
    start: new Date(`${currentYear}-03-06T00:00:00`),
    end: new Date(`${currentYear}-04-14T23:59:59`),
    emoji: {
      morning: '🌷',
      afternoon: '🌸',
      evening: '🌈',
    },
  },
  summerOne: {
    start: new Date(`${currentYear}-05-07T00:00:00`),
    end: new Date(`${currentYear}-06-24T23:59:59`),
    emoji: {
      morning: '😎',
      afternoon: '🏖️',
      evening: '🙌',
    },
  },
  summerTwo: {
    start: new Date(`${currentYear}-06-25T00:00:00`),
    end: new Date(`${currentYear}-08-26T23:59:59`),
    emoji: {
      morning: '☀️',
      afternoon: '🤩',
      evening: '😎',
    },
  },
  default: {
    emoji: {
      morning: '☕️',
      afternoon: '☀️',
      evening: '🌙',
    },
  },
};
