import { assign } from 'lodash';

import {
  SET_ORDER_METHOD,
  FETCH_ORDER_PRICING,
  FETCH_ORDER_PRICING_SUCCESS,
  FETCH_ORDER_PRICING_ERROR,
  CLEAR_ORDER_PRICING,
  CLEAR_CART_AVAILABLE_REWARDS,
  SUBMIT_ORDER,
  SUBMIT_ORDER_ERROR,
  SUBMIT_ORDER_SUCCESS,
  SUBMIT_ORDER_GUEST,
  SUBMIT_ORDER_GUEST_SUCCESS,
  SUBMIT_ORDER_GUEST_ERROR,
  CLEAR_ORDER_STATUS,
  UPDATE_ORDER_DELIVERY_TYPE,
  INVALIDATE_MOST_RECENT_ORDER,
  SET_APPLY_CART_OFFER,
  REWARD_REDEEMED,
  SELECT_STORE_MENU,
  CLEAR_STORE_MENU,
  SELECT_PICKUP_OPTION,
} from '../actions/types';

import { CONTINUE_AS_GUEST } from 'shared/app/state/action-creators/types';

const defaultState = {
  deliveryType: 'ConsumeOutOfStore',
  orderStatus: { processing: false },
  previous: null,
  mostRecentOrderTime: null,
  appliedOffers: [],
};

// eslint-disable-next-line complexity,max-statements
export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_ORDER_METHOD:
      return assign({}, state, {
        selectedMethod: action.payload,
      });

    case FETCH_ORDER_PRICING: {
      return { ...state, pricing: { ...state.pricing, isLoading: true } };
    }
    case SELECT_STORE_MENU:
    case CLEAR_ORDER_PRICING: {
      return { ...state, pricing: { isLoading: false }, pickupOption: null };
    }

    case CLEAR_STORE_MENU:
      return {
        ...state,
        pickupOption: null,
      };

    case CLEAR_CART_AVAILABLE_REWARDS: {
      return { ...state, pricing: { cart: { offers: [] } } };
    }

    case CLEAR_ORDER_STATUS: {
      return { ...state, orderStatus: { processing: false } };
    }

    case FETCH_ORDER_PRICING_ERROR: {
      return {
        ...state,
        pricing: { isLoading: false, error: action.payload },
      };
    }

    case FETCH_ORDER_PRICING_SUCCESS: {
      return { ...state, pricing: { isLoading: false, ...action.payload } };
    }

    case CONTINUE_AS_GUEST: {
      return { ...state, continueAsGuest: true };
    }

    case SELECT_PICKUP_OPTION:
      return {
        ...state,
        pickupOption: action.payload.pickupOption,
      };

    case UPDATE_ORDER_DELIVERY_TYPE: {
      return { ...state, deliveryType: action.payload };
    }

    case SET_APPLY_CART_OFFER: {
      return { ...state, appliedOffers: action.payload };
    }

    case REWARD_REDEEMED: {
      return { ...state, appliedOffers: [] };
    }
    case SUBMIT_ORDER:
    case SUBMIT_ORDER_GUEST: {
      return {
        ...state,
        orderStatus: {
          processing: true,
          serviceTime: null,
          error: null,
        },
      };
    }

    case SUBMIT_ORDER_ERROR:
    case SUBMIT_ORDER_GUEST_ERROR: {
      return {
        ...state,
        orderStatus: {
          processing: false,
          serviceTime: null,
          error: action.payload,
        },
      };
    }

    case SUBMIT_ORDER_SUCCESS: {
      return {
        ...state,
        orderStatus: {
          processing: false,
          serviceTime: null,
          error: null,
        },
        previous: {
          ...state,
          orderStatus: {
            processing: false,
            serviceTime: action.payload,
            error: null,
          },
        },
        mostRecentOrderTime: Date.now(),
      };
    }

    case SUBMIT_ORDER_GUEST_SUCCESS: {
      return {
        ...state,
        continueAsGuest: null,
        orderStatus: {
          processing: false,
          serviceTime: null,
          error: null,
        },
        previous: {
          ...state,
          orderStatus: {
            processing: false,
            serviceTime: action.payload.serviceTime,
            guest: action.payload.guest,
            error: null,
          },
        },
        mostRecentOrderTime: Date.now(),
      };
    }

    case INVALIDATE_MOST_RECENT_ORDER: {
      return {
        ...state,
        previous: {},
        mostRecentOrderTime: null,
      };
    }

    default:
      return state;
  }
};
