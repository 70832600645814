import { omit } from 'lodash';

import reducer from './state/reducer';
import effects from './state/effects';
import routes from './util/build-routes';
import {
  ADD_PRODUCT_TO_CART,
  ADD_OOS_RECOMMENDATION_TO_CART,
  CHANGE_QUANTITY,
  CLEAR_CURRENT_CART,
  FETCH_ORDER_PRICING,
  INVALIDATE_MOST_RECENT_ORDER,
  REMOVE_FAVORITE_PRODUCT,
  REMOVE_FAVORITE_PRODUCT_SUCCESS,
  SAVE_FAVORITE_PRODUCT,
  SAVE_FAVORITE_PRODUCT_SUCCESS,
  SUBMIT_ORDER,
  SUBMIT_ORDER_SUCCESS,
  UPDATE_AVAILABILITY,
  FETCH_ORDER_PRICING_SUCCESS,
  SELECT_PICKUP_OPTION,
  FETCH_FAVORITE_PRODUCTS,
  FETCH_FAVORITE_PRODUCTS_SUCCESS,
  FETCH_PREVIOUS_ORDERS,
  FETCH_PREVIOUS_ORDERS_SUCCESS,
  FETCH_PRODUCT_DETAILS,
  FETCH_SIMILAR_ITEMS,
  GET_PRE_ORDER_PICKUP_ESTIMATE_SUCCESS,
  GET_ORDER_PICKUP_TIME_SUCCESS,
  GET_ORDER_PICKUP_TIME_ERROR,
} from './state/actions/types';

import {
  CONFIRM_STORE_MENU,
  SELECT_STORE_MENU,
  CLEAR_STORE_MENU,
} from 'store-locator/app/state/actions/types';
import {
  REQUEST_LOCATION_BY_NUMBER,
  PICKUP_OVERLAY_SEEN,
  REQUEST_LOCATION_BY_NUMBER_SUCCESS,
} from 'shared/app/state/action-creators/types';
import OrderPaymentBottomSheet from './components/order-payment-bottom-sheet';

export default {
  name: 'ordering',
  routes,
  reducer,
  effects,
  asyncActions: [
    FETCH_ORDER_PRICING,
    SUBMIT_ORDER,
    FETCH_FAVORITE_PRODUCTS,
    FETCH_PREVIOUS_ORDERS,
    FETCH_PRODUCT_DETAILS,
    REQUEST_LOCATION_BY_NUMBER,
    SAVE_FAVORITE_PRODUCT,
    REMOVE_FAVORITE_PRODUCT,
    FETCH_SIMILAR_ITEMS,
  ],
  bottomSheetRoutes: [
    {
      sheet: OrderPaymentBottomSheet,
      name: 'payment-summary',
    },
  ],
  persistAfter: [
    {
      action: SELECT_STORE_MENU,
      include: ['selectedStore'],
    },
    {
      action: CONFIRM_STORE_MENU,
      include: ['selectedStore'],
    },
    {
      action: CLEAR_STORE_MENU,
      include: ['selectedStore'],
    },
    {
      action: REQUEST_LOCATION_BY_NUMBER_SUCCESS,
      include: ['selectedStore'],
    },
    {
      action: UPDATE_AVAILABILITY,
      include: ['cart'],
    },
    {
      action: ADD_PRODUCT_TO_CART,
      include: ['cart'],
    },
    {
      action: ADD_OOS_RECOMMENDATION_TO_CART,
      include: ['cart'],
    },
    {
      action: CHANGE_QUANTITY,
      include: ['cart'],
    },
    {
      action: CLEAR_CURRENT_CART,
      include: ['cart', 'order'],
    },
    {
      action: SUBMIT_ORDER_SUCCESS,
      include: ['cart', 'order'],
    },
    {
      action: INVALIDATE_MOST_RECENT_ORDER,
      include: ['cart', 'order'],
    },
    {
      action: FETCH_PREVIOUS_ORDERS_SUCCESS,
      include: ['previousOrders'],
    },
    {
      action: FETCH_FAVORITE_PRODUCTS_SUCCESS,
      include: ['favorites'],
    },
    {
      action: REMOVE_FAVORITE_PRODUCT_SUCCESS,
      include: ['favorites'],
    },
    {
      action: SAVE_FAVORITE_PRODUCT_SUCCESS,
      include: ['favorites'],
    },
    {
      action: FETCH_ORDER_PRICING_SUCCESS,
      include: ['order'],
    },
    {
      action: SELECT_PICKUP_OPTION,
      include: ['order'],
    },
    {
      action: GET_ORDER_PICKUP_TIME_SUCCESS,
      include: ['orderPickupTime'],
    },
    {
      action: GET_ORDER_PICKUP_TIME_ERROR,
      include: ['orderPickupTime'],
    },
    {
      action: PICKUP_OVERLAY_SEEN,
      include: ['orderPickupTime'],
    },
    {
      action: GET_PRE_ORDER_PICKUP_ESTIMATE_SUCCESS,
      include: ['preOrderEstimate'],
    },
  ],
  mergeCachedAndBootstrapData: (cachedData, bootstrapData) => ({
    ...cachedData,
    ...omit(bootstrapData, [
      'selectedStore',
      'cart',
      'order',
      'previousOrders',
      'favorites',
    ]),
  }),
};
