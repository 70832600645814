import React from 'react';
import { useIntl } from 'react-intl';
import { push } from 'redux-first-history';

import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import DialogWithMessages from 'shared/app/components/dialog-with-messages';
import { giftFormDataSelector } from '../../state/selectors';

import messages from './messages';
import { useDispatch, useSelector } from 'react-redux';

export const PAYMENT_INSTRUMENT_EXPIRED_DIALOG_ID =
  'PaymentInstrumentExpiredDialogId';

export const PaymentInstrumentExpiredDialog = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const giftFormData = useSelector(giftFormDataSelector);
  const productNumber = giftFormData?.productNumber ?? '';

  return (
    <DialogWithMessages
      confirmText={formatMessage(messages.addGuestPaymentAgain)}
      onCancel={() => {
        dispatch(push(`/gift/${productNumber}`));
      }}
      onConfirm={() => {
        dispatch(push('/account/payment-method'));
      }}
    >
      <Heading
        className="pb2 pt1"
        id={PAYMENT_INSTRUMENT_EXPIRED_DIALOG_ID}
        size="sm"
        tagName="h2"
      >
        {formatMessage(messages.guestPaymentTokenExpiredHeader)}
      </Heading>
    </DialogWithMessages>
  );
};

export default PaymentInstrumentExpiredDialog;
