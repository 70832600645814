import { trackEvent } from 'shared/app/utils/uo-tracking';

// event name uses lowercase letters, it is non-standard and correct
const UO_IN_MAP_CUSTOM_EVENT = 'campaign_details';
const UO_IN_MAP_CAMPAIGN_NAME = 'maps_ordering';
const UO_IN_MAP_CAMPAIGN_MEDIUM = 'maps';
const UO_IN_MAP_SOURCE_APPLE = 'apple_maps';
const UO_IN_MAP_SOURCE_GOOGLE = 'google_maps';

const MapSource = {
  apple: UO_IN_MAP_SOURCE_APPLE,
  google: UO_IN_MAP_SOURCE_GOOGLE,
};

/*
 ** InMap Ordering is not a user initiated client-side event directly.
 ** It must be tracked in a manner consistent with its intent, thus
 ** the menu-by-store fetch is the closest client event available.
 ** Given the architecture, the event logging will occur when the desired
 ** store is fetched and stored (store-specific menu will use this storeNumber).
 */
export const shouldTrackInMapOrderEvent = (currentRoute) => {
  const { pathname, query } = currentRoute;
  const inMapSource = query?.inMapSource ?? null;
  const storeNumber = query?.storeNumber ?? null;
  return (
    pathname === '/menu' &&
    Boolean(MapSource[inMapSource]) &&
    Boolean(storeNumber)
  );
};

/**
 * @param {string} inMapOrderSource (apple|google),
 * @returns {Promise<void>} The event has been sent.
 */
/* eslint-disable camelcase */
export const trackInMapOrder = (inMapOrderSource) =>
  trackEvent(UO_IN_MAP_CUSTOM_EVENT, {
    campaign_name: UO_IN_MAP_CAMPAIGN_NAME,
    campaign_medium: UO_IN_MAP_CAMPAIGN_MEDIUM,
    campaign_source: MapSource[inMapOrderSource],
  });
/* eslint-enable camelcase */
