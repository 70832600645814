import { defineMessages } from 'react-intl';

export default defineMessages({
  maximumItems: {
    id: 'ordering.maximumItems',
    defaultMessage: 'Maximum order is {limit} items. Please adjust your order.',
    description:
      'Text notifying user that no more items can be added to the cart for this order.',
  },
  addProductToCart: {
    id: 'ordering.addProductToCart',
    defaultMessage: '{productName} added',
    description:
      'Text notifying the user that they added a product to the cart.',
  },
  addProductToCartAria: {
    id: 'ordering.addToCartButton',
    defaultMessage: 'Add one {itemName} to cart.',
    description: 'Aria label for add to cart button.',
  },
  addProductToFavorites: {
    id: 'ordering.addProductToFavorites',
    defaultMessage: '{productName} added',
    description:
      'Text notifying the user that they added a product to favorites.',
  },
  addProductToFavoritesAria: {
    id: 'ordering.addToFavoritesButton',
    defaultMessage: 'Add {productName} to favorites.',
    description: 'Aria label for add to favorites button.',
  },
  allProductsLink: {
    id: 'ordering.allProductsLink',
    defaultMessage: 'Menu',
    description: 'Subnav link to direct back to root menu',
  },
  removeProductFromFavoritesAria: {
    id: 'ordering.removeFromFavoritesButton',
    defaultMessage: 'Remove {productName} from favorites.',
    description: 'Aria label for remove from favorites button.',
  },
  menuDescription: {
    id: 'ordering.menu.metaDescription',
    defaultMessage:
      'Check out Starbucks menu and get nutritional information about each menu item.',
    description: 'Meta description provided for menu and category pages',
  },
  subcategoryDefault: {
    id: 'ordering.subcategoryDefault',
    defaultMessage: 'All',
    description:
      'A link inside of a subcategory that takes a user back to seeing all products',
  },
  previousTitle: {
    id: 'ordering.previousTitle',
    defaultMessage: 'Previous',
    description: 'The title of the previous orders page',
  },
  favoritesTitle: {
    id: 'ordering.favoritesTitle',
    defaultMessage: 'Favorites',
    description: 'The title of the favorites orders page',
  },
  featuredTitle: {
    id: 'ordering.featuredTitle',
    defaultMessage: 'Featured',
    description: 'The title of the featured orders page',
  },
  itemsLabel: {
    id: 'ordering.itemsLabel',
    defaultMessage: 'Items',
    description: 'Label for items',
  },
  changeOrder: {
    id: 'ordering.cart.changeOrder',
    defaultMessage: 'Change order',
    description:
      'Call to action indicating the user would like to change their order',
  },
  checkoutButton: {
    id: 'ordering.cart.checkoutButton',
    defaultMessage: 'Checkout',
    description: 'Text for the checkout button',
  },
  unavailableItemsDialogTitle: {
    id: 'ordering.cart.unavailableItemsDialog.title',
    defaultMessage:
      '{itemCount, plural, one {1 item is} other {{itemCount} items are}} not available at this store',
    description:
      'Tells the user that no items in their cart are available at the selected store.',
  },
  unavailableItemsDialogMessage: {
    id: 'ordering.cart.unavailableItemsDialog.message',
    defaultMessage:
      'You can continue to checkout without {itemCount, plural, one {this item} other {these items}}, or go back to your order to change it.',
    description:
      'Tells the user they need to edit their order or place the order but not get the unavailable item.',
  },
  noAvailableItemsDialogTitle: {
    id: 'ordering.cart.noAvailableItemsDialog.title',
    defaultMessage: 'No items in your order are available',
    description:
      'Tells the user that no items in their cart are available at the selected store.',
  },
  noAvailableItemsDialogMessage: {
    id: 'ordering.cart.noAvailableItemsDialog.message',
    defaultMessage:
      'You can try a different store or go back to your order to add something else.',
    description:
      'Tells the user they need to select a new store or edit their order to check out.',
  },
  unorderableProduct: {
    id: 'ordering.cart.unorderableProduct',
    defaultMessage: 'Unavailable to purchase online',
    description:
      'Message alerting the customer that this product cannot be purchased online.',
  },
  temporarilyUnavailable: {
    id: 'ordering.cart.temporarilyUnavailable',
    defaultMessage: 'Sold out at this store.',
    description:
      'Message alerting the customer that this product is sold out at the selected store.',
  },
  notAvailableHere: {
    id: 'ordering.cart.notAvailableHere',
    defaultMessage: 'Not sold at this store.',
    description:
      'Message alerting the customer that this product is not sold at the selected store.',
  },
  toPlaceOrderHeading: {
    id: 'ordering.joinRewardsSigninSheet.toPlaceOrderHeading',
    defaultMessage: 'How do you want to complete your purchase?',
    description:
      'Content to inform user they need to continue as guest, login or create an account to place an order',
  },
  unavailableProduct: {
    id: 'ordering.cart.unavailableProduct',
    defaultMessage: 'This item is unavailable',
    description:
      'Message alerting the customer that this product is currently unavailable.',
  },
  pickupOptionsLabel: {
    id: 'ordering.pickupOptionsLabel',
    defaultMessage: 'Pickup options',
    description:
      'Call to action label for navigating user to pick up options section',
  },
  pickupMethodLabel: {
    id: 'ordering.pickupMethodLabel',
    defaultMessage: 'Pickup method',
    description: 'Label to show only one method for picking up',
  },
  iAmHereLabel: {
    id: 'ordering.iAmHereLabel',
    defaultMessage: `I'm here`,
    description:
      'Call to action label for navigating user to selected pick up options section',
  },
  productCategoriesLabel: {
    id: 'ordering.sideNav.productCategoriesLabel',
    defaultMessage: 'Product categories',
    description: 'label for aria identification of nav purpose',
  },
  similarItemsCta: {
    id: 'ordering.similarItems.cta',
    defaultMessage: 'See similar items',
    description: 'Cta to see similar items',
  },
});
