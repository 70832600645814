import { push } from 'redux-first-history';

import { fetchLocationByNumber } from 'shared/app/state/action-creators/fetch-location';
import { currentRouteSelector } from 'shared/app/state/selectors/routes';
import { CONFIRM_ORDERING_UNAVAILABLE } from 'shared/app/state/action-creators/types';

import { SELECT_STORE_MENU, SET_LOCATION_BY_NUMBER } from './types';
import {
  shouldTrackInMapOrderEvent,
  trackInMapOrder,
} from './track-in-map-order-event';

export const setStoreByNumber =
  ({ storeNumber } = {}) =>
  (dispatch, getState) => {
    const currentRoute = currentRouteSelector(getState());

    dispatch({ type: SET_LOCATION_BY_NUMBER });

    dispatch(fetchLocationByNumber(storeNumber)).then((selectedStore) => {
      if (!selectedStore) {
        return null;
      }

      dispatch({
        type: SELECT_STORE_MENU,
        payload: {
          location: selectedStore,
          needsConfirmation: false,
        },
      });

      if (shouldTrackInMapOrderEvent(currentRoute)) {
        trackInMapOrder(currentRoute.query.inMapSource);
      }

      if (currentRoute?.query?.confirmedOrderingUnavailable === storeNumber) {
        dispatch({
          type: CONFIRM_ORDERING_UNAVAILABLE,
          payload: { storeNumber },
        });
      }
      dispatch(push('/menu'));
    });
  };
