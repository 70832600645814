import createActionTypes from 'shared/app/state/create-action-types';

export const FETCH_DRINK_FINDER_NEXT_QUESTION =
  'FETCH_DRINK_FINDER_NEXT_QUESTION';
export const FETCH_DRINK_FINDER_RESET = 'FETCH_DRINK_FINDER_RESET';
export const FETCH_DRINK_FINDER_UPDATE_BREADCRUMBS =
  'FETCH_DRINK_FINDER_UPDATE_BREADCRUMBS';
export const UPDATE_DRINK_FINDER = 'UPDATE_DRINK_FINDER';

export const {
  FETCH_DRINK_FINDER,
  FETCH_DRINK_FINDER_SUCCESS,
  FETCH_DRINK_FINDER_ERROR,
} = createActionTypes(['FETCH_DRINK_FINDER']);
