import { lazyFullPage } from 'shared/app/shell';
import reducer from './state/reducers';

export default {
  name: 'drink-finder',
  asyncActions: ['FETCH_DRINK_FINDER'],
  reducer,
  routes: {
    '/drink-finder': {
      fullPageComponent: lazyFullPage(() => import('./components/')),
      fullWidthBottomDrawer: true,
    },
  },
  persistAfter: ['FETCH_DRINK_FINDER'],
};
