/* eslint-disable max-statements */
import { isEmpty } from 'lodash';
const ms = require('milliseconds');
import { push } from 'redux-first-history';

import logOptionsMissingSkus from '../../util/log-options-missing-skus';
import { sortSelectedOptions } from '../../util/cart-content-util';
import getThumbnailUri from 'shared/app/utils/get-thumbnail-uri';
import { selectedStoreSelector } from 'shared/app/state/selectors/ordering';
import {
  createGuestProfile,
  deleteGuestCookies,
} from 'shared/app/state/action-creators/guest';
import { signedInSelector } from 'shared/app/bundles/user';
import {
  hideBottomSheet,
  showUnexpectedErrorNotification,
} from 'shared/app/shell';
import {
  addDigitalSvcCard,
  reloadAmountSelectedSelector,
  clearReloadAmountSelected,
  getSvcCardBalance,
  reloadSvcCardBalance,
} from 'shared/app/bundles/svc-cards';
import {
  PAYMENT_TYPE_SVC,
  fetchWallet,
  createApplePaySingleUsePayment,
} from 'shared/app/bundles/wallet';
import { isCoreAppUrlSelector } from 'shared/app/state/selectors/locales';
import PickupOverlay from 'shared/app/components/order-pickup-overlay';
import { accertifyDataCollectorIsEnabledSelector } from 'shared/app/state/selectors/accertify-data-collector';
import { showCodedErrorNotification } from 'shared/app/utils/show-coded-error-notification';
import runSequentially from 'shared/app/utils/run-sequentially';
import { getFraudReputation } from 'shared/app/utils/iovation';
import { createGuestSession } from 'shared/app/state/action-creators/guest';

import {
  deliveryTypeSelector,
  slimCartSelector,
  appliedCartOffersSelector,
  cartHasOfferAppliedSelector,
  currentPickupOptionSelector,
  pricingLoadingSelector,
} from '../../state/selectors';

import { transformCart } from '../../../universal/utils/transform-cart';

import {
  getNotificationForPricingError,
  UNEXPECTED_ERROR,
  REWARD_NOT_APPLIED,
} from './errors/pricing';
import {
  getNotificationForSubmitError,
  DIGITAL_CARD_PROVISION_ERROR,
  INSUFFICIENT_BALANCE,
} from './errors/submit-order';

import {
  ADD_PRODUCT_TO_CART,
  ADD_OOS_RECOMMENDATION_TO_CART,
  CLEAR_CURRENT_CART,
  SET_APPLY_CART_OFFER,
  CHANGE_QUANTITY,
  FETCH_ORDER_PRICING,
  FETCH_ORDER_PRICING_SUCCESS,
  FETCH_ORDER_PRICING_ERROR,
  CLEAR_ORDER_PRICING,
  CLEAR_CART_AVAILABLE_REWARDS,
  SUBMIT_ORDER,
  SUBMIT_ORDER_SUCCESS,
  SUBMIT_ORDER_ERROR,
  SUBMIT_ORDER_GUEST,
  SUBMIT_ORDER_GUEST_SUCCESS,
  CLEAR_ORDER_STATUS,
  UPDATE_ORDER_DELIVERY_TYPE,
  REWARD_REDEEMED,
  HIDE_ADDED_PRODUCT_NOTIFICATION,
  SELECT_PICKUP_OPTION,
} from './types';

import { PICKUP_OVERLAY_SEEN } from 'shared/app/state/action-creators/types';

import {
  PRICE_ORDER,
  PLACE_ORDER,
  PLACE_ORDER_GUEST,
  PRICE_ORDER_GUEST,
} from '../../../universal/gql-operation-ids';
import { getOrderPickupTime } from '../actions/order-pickup-time';

import { CLEAR_STORE_CONFIRMATION } from 'store-locator/app/state/actions/types';
import { commonMessages } from 'shared/app/messages';
import { getShortStoreNumber } from 'shared/app/bundles/menu/util/get-filter-query';

import NoAvailableItemsDialog, {
  NO_AVAILABLE_ITEMS_DIALOG_ID,
} from 'ordering/app/components/cart/no-available-items-dialog';
import UnavailableItemsDialog, {
  UNAVAILABLE_ITEMS_DIALOG_ID,
} from 'ordering/app/components/cart/unavailable-items-dialog';
import {
  allItemsAreUnavailable,
  itemsThatAreUnavailable,
} from 'ordering/app/components/cart/util';
export const hideAddedProductNotification = () => ({
  type: HIDE_ADDED_PRODUCT_NOTIFICATION,
});

export const changeQuantity = ({ delta, itemId }) => ({
  type: CHANGE_QUANTITY,
  payload: { delta, itemId },
});

export const clearPricing = () => ({
  type: CLEAR_ORDER_PRICING,
});

export const clearOrderStatus = () => ({
  type: CLEAR_ORDER_STATUS,
});

export const clearCartAvailableRewards = () => (dispatch) =>
  dispatch({ type: CLEAR_CART_AVAILABLE_REWARDS });

export const clearCartItems = () => (dispatch) =>
  dispatch({ type: CLEAR_CURRENT_CART });

export const transformItemToAdd = ({
  product,
  sizeCode,
  selectedOptions,
  quantity,
}) => {
  // Temporarily reporting the condition of a missing sku to troubleshoot
  // product data. The reporting can be remove once the root cause of the
  // missing skus has been identified.
  logOptionsMissingSkus(selectedOptions);

  // Did sizeCode get passed in? If so, find a size in the product
  // where its sizeCode matches the one passed in.
  const size = sizeCode
    ? (product?.sizes || product?.forms?.[0]?.sizes).find(
        (_size) => _size.sizeCode === sizeCode
      )
    : product?.sizes?.[0] || product?.forms?.[0]?.sizes?.[0];

  const productImage = getThumbnailUri(product);

  const sortedOptions = sortSelectedOptions(selectedOptions);
  return {
    product,
    size,
    quantity,
    selectedOptions: sortedOptions,
    productImage,
  };
};

export const editItemInCart =
  ({
    quantity,
    product,
    sizeCode,
    selectedOptions,
    editedItemId,
    originalCartItem,
  }) =>
  (dispatch) => {
    if (!product || !sizeCode || !quantity) {
      dispatch(showUnexpectedErrorNotification());
      throw new Error('Product data is invalid');
    }

    if (editedItemId !== originalCartItem) {
      dispatch(changeQuantity({ delta: -1, itemId: originalCartItem }));
      dispatch({
        type: ADD_PRODUCT_TO_CART,
        payload: transformItemToAdd({
          product,
          sizeCode,
          selectedOptions,
          quantity,
        }),
      });
    }
    dispatch(push('/menu/cart'));
  };

export const addProductToCart =
  ({
    outOfStockItemKey,
    product,
    quantity,
    sizeCode,
    selectedOptions,
    // everything else received is trackingMetadata
    // currently these properties can include:
    // --------------------------
    // availabilityWhenAdded
    // deepbrewRecommendationType
    // isRecommendedProduct
    // isUsual
    // moodName
    // productAddSource
    // recommendationId
    // recommendationRank
    // recommendationType
    // storeNumber
    ...trackingMetadata
  }) =>
  (dispatch) => {
    if (!product || !sizeCode || !quantity) {
      dispatch(showUnexpectedErrorNotification());
      throw new Error('Product data is invalid');
    }

    const transformedItemForCart = transformItemToAdd({
      product,
      quantity,
      selectedOptions,
      sizeCode,
    });

    const actionType = outOfStockItemKey
      ? ADD_OOS_RECOMMENDATION_TO_CART
      : ADD_PRODUCT_TO_CART;
    return dispatch({
      type: actionType,
      payload: {
        ...transformedItemForCart,
        ...trackingMetadata,
        ...(outOfStockItemKey && { outOfStockItemKey }),
      },
    });
  };

const consumptionTypeMap = {
  'ConsumeInStore': 'CONSUME_IN_STORE',
  'ConsumeOutOfStore': 'CONSUME_OUT_OF_STORE',
};

const collectionTypeMap = {
  '16': 'IN_STORE',
  'DT': 'DRIVE_THRU',
  'CX': 'CURBSIDE',
  '17': 'OUTDOOR',
};

export const fetchPricing =
  () =>
  (dispatch, getState, { gqlFetch }) => {
    // Dispatch immediately so that reducer sets `pricingNeedsFetching` to false
    // and fetchPricing is called only once
    const state = getState();

    const isPricingLoading = pricingLoadingSelector(state);

    if (isPricingLoading) {
      return;
    }

    dispatch({ type: FETCH_ORDER_PRICING });

    const cart = slimCartSelector(state);
    const isSignedIn = signedInSelector(state);
    const selectedStore = selectedStoreSelector(state)?.store || {};
    const storeNumber = selectedStore.storeNumber;

    const offers = appliedCartOffersSelector(state);
    const currentCollectionType =
      collectionTypeMap[currentPickupOptionSelector(state)] || 'IN_STORE';
    const currentConsumptionType =
      consumptionTypeMap[deliveryTypeSelector(state)] || 'CONSUME_OUT_OF_STORE';

    const offerCodes = offers.length
      ? offers.map((offer) => {
          return { code: offer.code };
        })
      : [];

    if (isEmpty(cart)) {
      return;
    }

    const opts = {
      operationId: isSignedIn ? PRICE_ORDER : PRICE_ORDER_GUEST,
      variables: {
        order: {
          cart: {
            items: transformCart(cart),
            offers: offerCodes,
          },
          fulfillment: {
            consumptionType: currentConsumptionType,
            collectionType: currentCollectionType,
          },
          storeNumber,
        },
      },
    };
    return (
      gqlFetch(opts)
        // eslint-disable-next-line max-statements
        .then((data) => {
          if (data.priceOrder.__typename === 'OpenAPIError') {
            dispatch({
              type: FETCH_ORDER_PRICING_ERROR,
              payload: new Error(
                'There was an error getting the order pricing.'
              ),
            });
            dispatch(
              showCodedErrorNotification(
                data.priceOrder.code,
                getNotificationForPricingError
              )
            );
            return;
          }
          const expiresAt = ms.seconds(data.priceOrder.expiresIn) + Date.now();
          dispatch({
            type: FETCH_ORDER_PRICING_SUCCESS,
            payload: {
              ...data.priceOrder,
              store: {
                ...data.priceOrder.store,
                storeNumber,
                ...selectedStore,
              },
              expiresAt,
            },
          });

          // check if the intended offer was not applied during pricing and clear the offer if so.
          return offers?.map((offer) => {
            const foundOffer = (data.priceOrder.cart.offers || []).find(
              (cartOffer) => cartOffer.code === offer.code.toString()
            );
            if (foundOffer && !foundOffer.hasBeenApplied) {
              dispatch({ type: SET_APPLY_CART_OFFER, payload: [] });
              dispatch(
                showCodedErrorNotification(
                  REWARD_NOT_APPLIED,
                  getNotificationForPricingError
                )
              );
            }
          });
        })
        .catch((err) => {
          if (err?.guestSessionExpired) {
            runSequentially(
              () => dispatch(deleteGuestCookies()),
              async () => {
                const reputation = await getFraudReputation();
                dispatch(createGuestSession({ reputation }));
              }
            );
          }
          // SpecialCase errors are handled by orchestra and meant to simplify how some errors are grouped.
          // https://docs.starbucks.com/pages/viewpage.action?pageId=374514123
          const { code, specialCase, message } = err || {};
          dispatch({ type: FETCH_ORDER_PRICING_ERROR, payload: err });
          dispatch(
            showCodedErrorNotification(
              specialCase || code,
              getNotificationForPricingError,
              { translatedMessage: message }
            )
          );
        })
    );
  };

export const updateOrderDeliveryType = (type) => (dispatch, getState) => {
  if (type === deliveryTypeSelector(getState())) {
    return;
  }
  runSequentially(
    () => dispatch({ type: UPDATE_ORDER_DELIVERY_TYPE, payload: type }),
    () => dispatch(fetchPricing())
  );
};

const validateOrderPricing = ({ pricing, cardId, isGuest = false }) => {
  if (!pricing) {
    return {
      error: new Error('Pricing request is missing from the store.'),
      code: UNEXPECTED_ERROR,
    };
  }

  const { summary: { price } = {}, orderId } = pricing;

  if (!orderId) {
    return {
      error: new Error(`
        Missing transaction data.
        orderId: ${orderId}
      `),
      code: UNEXPECTED_ERROR,
    };
  }

  if (!isGuest && (parseFloat(price) < 0 || !cardId)) {
    return {
      error: new Error(`
        Insufficient balance on card or missing cardId.
        totalAmount: ${price}, cardId: ${cardId}
      `),
      code: INSUFFICIENT_BALANCE,
    };
  }

  return { orderId, price };
};

export const submitOrder =
  ({
    formatMessage,
    openModal,
    onSuccess,
    onError,
    paymentId,
    tender,
    tipAmount,
  }) =>
  async (dispatch, getState, { gqlFetch }) => {
    const state = getState();
    const pricing = getState()?.ordering?.order?.pricing;
    const {
      orderId,
      price,
      error: validationError,
      code,
    } = validateOrderPricing({ pricing, cardId: paymentId });

    if (validationError && code) {
      dispatch({
        type: SUBMIT_ORDER_ERROR,
        payload: validationError,
      });
      dispatch(showCodedErrorNotification(code, getNotificationForSubmitError));
      onError && onError(code);
      return;
    }
    const selectedStore = selectedStoreSelector(getState())?.store;
    const variables = {
      subInp: {
        orderId,
        storeNumber: selectedStore?.storeNumber,
        tenders: [{ id: paymentId, tender, amount: price }],
        tipAmount: parseFloat(tipAmount),
      },
    };

    dispatch({ type: SUBMIT_ORDER });

    return gqlFetch({
      operationId: PLACE_ORDER,
      variables,
      includeRisk: true,
      includeAccertify: accertifyDataCollectorIsEnabledSelector(state),
    })
      .then((data) => {
        if (!data?.submitOrder) {
          dispatch({
            type: SUBMIT_ORDER_ERROR,
            payload: new Error('No valid service time.'),
          });
          dispatch(
            showCodedErrorNotification(
              UNEXPECTED_ERROR,
              getNotificationForSubmitError
            )
          );
          onError && onError(UNEXPECTED_ERROR);
          return;
        }

        const cartHasAppliedOffer = cartHasOfferAppliedSelector(getState());

        const checkOfferRedemption = () => {
          if (!cartHasAppliedOffer) {
            return;
          }
          return dispatch({ type: REWARD_REDEEMED });
        };

        const reloadAmountSelected = reloadAmountSelectedSelector(getState());

        runSequentially(
          () =>
            dispatch(
              getOrderPickupTime({
                orderId,
                shortStoreNumber: getShortStoreNumber(
                  selectedStore?.storeNumber
                ),
              })
            ),
          () => dispatch(hideBottomSheet({ redirectUrl: '/menu/previous' })),
          () =>
            dispatch({ type: SUBMIT_ORDER_SUCCESS, payload: data.submitOrder }),
          () =>
            openModal({
              component: PickupOverlay,
              modalRootProps: {
                'aria-label': formatMessage(commonMessages.success),
              },
              onClose: () => dispatch({ type: PICKUP_OVERLAY_SEEN }),
            }),
          () => checkOfferRedemption(),
          () => dispatch(clearPricing()),
          () => {
            if (tender === PAYMENT_TYPE_SVC) {
              dispatch(getSvcCardBalance(paymentId));
            }
          },
          () => dispatch(fetchWallet()),
          () => dispatch({ type: CLEAR_STORE_CONFIRMATION }),
          () => reloadAmountSelected && dispatch(clearReloadAmountSelected())
        );

        onSuccess &&
          onSuccess({
            orderResponse: data.submitOrder,
            orderPricing: pricing,
          });
      })
      .catch((error) => {
        const { code: errorCode, message } = error;
        dispatch({ type: SUBMIT_ORDER_ERROR, payload: error });
        dispatch(
          showCodedErrorNotification(errorCode, getNotificationForSubmitError, {
            translatedMessage: message,
          })
        );
        onError && onError(error.code);
      });
  };

export const loadAndSubmitOrder =
  ({ formatMessage, formData, openModal, tender, tipAmount, ...other }) =>
  (dispatch) => {
    const { payment } = formData;
    dispatch(reloadSvcCardBalance(formData))
      .then(() => {
        dispatch(
          submitOrder({
            formatMessage,
            openModal,
            paymentId: payment,
            tender,
            tipAmount,
            ...other,
          })
        );
      })
      .catch((error) => {
        const { code } = error;
        dispatch(
          showCodedErrorNotification(code, getNotificationForSubmitError)
        );
      });
  };
export const applyOffer =
  ({ code, stars, type, couponId }) =>
  (dispatch) => {
    dispatch({
      type: SET_APPLY_CART_OFFER,
      payload: code ? [{ code, stars, type, couponId }] : [],
    });
  };

export const addDigitalCardAtCheckout = (showBottomSheet) => (dispatch) => {
  return dispatch(addDigitalSvcCard())
    .then(() => dispatch(showBottomSheet()))
    .catch(() => {
      return dispatch(
        showCodedErrorNotification(
          DIGITAL_CARD_PROVISION_ERROR,
          getNotificationForSubmitError
        )
      );
    });
};

export const selectPickupOption =
  ({ pickupOption }) =>
  (dispatch) => {
    return dispatch({
      type: SELECT_PICKUP_OPTION,
      payload: {
        pickupOption,
      },
    });
  };

export const guestSubmitOrder =
  ({
    formatMessage,
    openModal,
    paymentInstrumentId,
    tender,
    firstName,
    lastName,
    email,
  }) =>
  (dispatch, getState, { gqlFetch }) => {
    const state = getState();
    const isCoreAppUrl = isCoreAppUrlSelector(state);

    const pricing = state?.ordering?.order?.pricing;
    const {
      orderId,
      price,
      error: validationError,
      code,
    } = validateOrderPricing({ pricing, isGuest: true });

    if (validationError && code) {
      throw new Error(code);
    }
    const selectedStore = selectedStoreSelector(state)?.store;
    const variables = {
      subInp: {
        orderId,
        storeNumber: selectedStore?.storeNumber,
        tenders: [{ id: paymentInstrumentId, tender, amount: price }],
        customer: {
          displayName: firstName,
          lastInitial: lastName?.[0],
        },
      },
    };

    dispatch({ type: SUBMIT_ORDER_GUEST });

    return gqlFetch({
      operationId: PLACE_ORDER_GUEST,
      variables,
      includeRisk: true,
      includeAccertify: accertifyDataCollectorIsEnabledSelector(state),
    })
      .then((data) => {
        if (!data?.submitOrder) {
          throw new Error('No response data');
        }

        runSequentially(
          () =>
            dispatch(
              getOrderPickupTime({
                orderId,
                shortStoreNumber: getShortStoreNumber(
                  selectedStore?.storeNumber
                ),
              })
            ),
          () => dispatch(push(isCoreAppUrl ? '/' : '/menu/previous')),
          () =>
            dispatch({
              type: SUBMIT_ORDER_GUEST_SUCCESS,
              payload: {
                serviceTime: data.submitOrder,
                guest: { firstName, email },
              },
            }),
          () =>
            openModal({
              component: PickupOverlay,
              modalRootProps: {
                'aria-label': formatMessage(commonMessages.success),
              },
              onClose: () => dispatch({ type: PICKUP_OVERLAY_SEEN }),
            }),
          () => dispatch(deleteGuestCookies()),
          () => dispatch(clearPricing()),
          () => dispatch({ type: CLEAR_STORE_CONFIRMATION }),
          () => dispatch({ type: CLEAR_CURRENT_CART }),
          async () => {
            const reputation = await getFraudReputation();
            return dispatch(createGuestSession({ reputation }));
          }
        );
      })
      .catch((error) => {
        throw error;
      });
  };

export const checkUnavailableTransaction =
  ({ openModal }) =>
  (dispatch, getState) => {
    const state = getState();
    return new Promise(async (resolve) => {
      try {
        const pricing = state?.ordering?.order?.pricing;
        const pricedCartItems = pricing?.cart?.items;
        const pricingError = pricing?.error?.code;

        if (!pricedCartItems && !pricingError) {
          // Do not check if there is no pricing information when GCO is off
          return resolve();
        }
        const noAvailableItems = allItemsAreUnavailable(pricedCartItems);
        const unavailableItems = itemsThatAreUnavailable(pricedCartItems);
        if (
          pricingError ||
          (noAvailableItems &&
            pricedCartItems.length === unavailableItems.length)
        ) {
          return openModal({
            component: NoAvailableItemsDialog,
            ariaLabelledBy: NO_AVAILABLE_ITEMS_DIALOG_ID,
          });
        }

        if (unavailableItems?.length) {
          return openModal({
            component: UnavailableItemsDialog,
            ariaLabelledBy: UNAVAILABLE_ITEMS_DIALOG_ID,
            componentProps: {
              items: unavailableItems,
              hasValidOrderPricing: false,
            },
          });
        }
        return resolve();
      } catch {
        return resolve();
      }
    });
  };

const guestSubmitOrderErrorMap = {
  '012-001-052': 'ordering.orderPaymentBottomSheet.notification.pricingRefresh',
  // 012-001-021: OpenAPIError Customer Id has changed
  // The session used to price order has to match the submit order one
  // In this very edge case, We prompt the user to refresh the page.
  '012-001-021': 'ordering.orderPaymentBottomSheet.notification.pricingRefresh',
};

const guestSubmitOrderErrorSpecialCases = ['STORE_CLOSED'];

const guestTransactionErrorHandler = ({ error, dispatch }) => {
  if (error?.guestSessionExpired) {
    runSequentially(
      () => dispatch(deleteGuestCookies()),
      // session used for price-order has to be the same for submit order
      () => dispatch(clearPricing()),
      async () => {
        const reputation = await getFraudReputation();
        dispatch(createGuestSession({ reputation }));
      }
    );
  }
  const transactionError = new Error();
  transactionError.errorMessageId = guestSubmitOrderErrorMap[error?.code];
  transactionError.errorTranslatedMessage =
    guestSubmitOrderErrorSpecialCases.includes(error?.specialCase)
      ? error?.message
      : null;
  throw transactionError;
};

export const guestApplePayTransaction =
  ({ applePayPaymentInfo, formatMessage, openModal }) =>
  async (dispatch) => {
    const { billingContact, shippingContact } = applePayPaymentInfo;
    try {
      await dispatch(
        createGuestProfile({
          email: shippingContact.emailAddress,
          firstName: billingContact.givenName,
        })
      );

      const { paymentInstrumentId } =
        (await dispatch(
          createApplePaySingleUsePayment({
            applePayPaymentInfo,
          })
        )) ?? {};

      await dispatch(
        guestSubmitOrder({
          formatMessage,
          paymentInstrumentId,
          openModal,
          tender: 'APPLE_PAY',
          firstName: billingContact.givenName,
          lastName: billingContact.familyName,
          email: shippingContact.emailAddress,
        })
      );
    } catch (error) {
      return guestTransactionErrorHandler({ error, dispatch });
    }
  };

export const guestCreditDebitCardTransaction =
  ({
    paymentInstrumentId,
    paymentType,
    firstName,
    lastName,
    email,
    formatMessage,
    openModal,
  }) =>
  async (dispatch) => {
    try {
      await dispatch(
        createGuestProfile({
          email,
          firstName,
        })
      );

      await dispatch(
        guestSubmitOrder({
          formatMessage,
          paymentInstrumentId,
          openModal,
          tender: paymentType,
          firstName,
          lastName,
          email,
        })
      );
    } catch (error) {
      return guestTransactionErrorHandler({ error, dispatch });
    }
  };
