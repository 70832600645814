import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { range } from 'lodash';

import ImageLoader from '@starbucks-web/pattern-library/lib/components/image-loader';
import Heading from '@starbucks-web/pattern-library/lib/components/heading/';

import DialogWithMessages from 'shared/app/components/dialog-with-messages';
import { sharedCallsToAction } from 'shared/app/messages/ctas';
import { getThumbnailUriFromMaster } from 'shared/app/utils/get-thumbnail-uri';

import styles from './unavailable-items-dialog-styles.cssm';
import messages from '../../messages';

const getItemCount = (items) => {
  return items.reduce((acc, item) => {
    acc += item?.quantity ?? 1;
    return acc;
  }, 0);
};

// This function makes sure to render duplicates. It passes _all_
// items off to other functions for different rendering designs.
const renderUnavailableProducts = (items, renderProduct) => {
  return items.map((item, idx) => {
    const duplicateItemCount = item?.quantity;

    if (duplicateItemCount > 1) {
      return range(0, duplicateItemCount).map((index) => {
        return renderProduct(item, `${idx}.${index}`);
      });
    }
    return renderProduct(item, idx);
  });
};

const renderSoloProduct = (item, index) => {
  const productName = item?.label;
  return (
    <div className="flex items-center my3" key={index}>
      <ImageLoader
        includeFallback={{
          enabled: true,
          wrapperClassName: styles.soloImgWrapper,
        }}
        lazyLoad={{ enabled: false }}
        role="presentation"
        src={getThumbnailUriFromMaster(item?.masterImageUrl)}
      />
      <div className="ml3 text-md">{productName}</div>
    </div>
  );
};

const renderMultiProducts = (item, index) => {
  const productName = item?.label;
  return (
    <ImageLoader
      alt={productName}
      includeFallback={{
        enabled: true,
        wrapperClassName: styles.multiImgWrapper,
        fallbackClassName: styles.multiImgFallback,
      }}
      key={index}
      lazyLoad={{ enabled: false }}
      src={getThumbnailUriFromMaster(item?.masterImageUrl)}
    />
  );
};

export const UNAVAILABLE_ITEMS_DIALOG_ID = 'UnavailableItemsDialogId';

export const UnavailableItemsDialog = ({
  items,
  hasValidOrderPricing,
  showCorrectBottomSheet,
}) => {
  const totalUnavailable = getItemCount(items);

  const changeOrderText = <FormattedMessage {...messages.changeOrder} />;

  // Configure the Unavailable Items Dialog w or w/o the 'continue' button
  // depending on whether or not the items have been price successfully
  const dialogProps = hasValidOrderPricing
    ? {
        confirmText: <FormattedMessage {...sharedCallsToAction.continue} />,
        cancelText: changeOrderText,
        onConfirm: () => {
          showCorrectBottomSheet();
        },
      }
    : {
        confirmButtonOnly: true,
        confirmText: changeOrderText,
      };

  return (
    <DialogWithMessages {...dialogProps}>
      <Heading className="mb3" id={UNAVAILABLE_ITEMS_DIALOG_ID} tagName="h2">
        <FormattedMessage
          {...messages.unavailableItemsDialogTitle}
          values={{ itemCount: totalUnavailable }}
        />
      </Heading>
      {hasValidOrderPricing ? (
        <FormattedMessage
          {...messages.unavailableItemsDialogMessage}
          values={{ itemCount: totalUnavailable }}
        />
      ) : (
        <FormattedMessage {...messages.noAvailableItemsDialogMessage} />
      )}
      {totalUnavailable <= 2 ? (
        <div className="mt5">
          {renderUnavailableProducts(items, renderSoloProduct)}
        </div>
      ) : (
        <div className="mt5 flex flex-wrap">
          {renderUnavailableProducts(items, renderMultiProducts)}
        </div>
      )}
    </DialogWithMessages>
  );
};

UnavailableItemsDialog.propTypes = {
  items: PropTypes.array,
};

export default UnavailableItemsDialog;
