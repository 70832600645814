import React, { useEffect, useState } from 'react';
import qs from 'query-string';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Animator from '@starbucks-web/pattern-library/lib/components/animator';
import Card from '@starbucks-web/pattern-library/lib/components/card/';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import Rule from '@starbucks-web/pattern-library/lib/components/rule';

import { fetchInitialDrinkFinderQuestion as fetchDrinkFinder } from 'shared/app/state/action-creators/drink-finder';
import {
  configSelector,
  configImagesUrlSelector,
} from 'shared/app/state/selectors/config';

import messages from './messages';
import styles from './styles.cssm';

const DrinkFinderStreamCard = ({
  configImagesUrl,
  enableDrinkFinder,
  fetchInitialDrinkFinderQuestion,
}) => {
  if (!enableDrinkFinder) {
    return null;
  }
  const [question, setQuestion] = useState('');
  const [questionId, setQuestionId] = useState(0);
  const [options, setOptions] = useState([]);
  const [sessionId, setSessionId] = useState('');
  const [fetchError, setFetchError] = useState(false);
  const [loading, setLoading] = useState(false);

  // UI is tightly coupled with first question and options. This
  // object handles sub-optimal data available for this question
  const streamCardQuestionMap = {
    Iced: {
      imgUrl: `${configImagesUrl}/drink-finder/iced_drink.png`,
      imgWidth: 63,
      optionMessageId: 'icedDrinkOptionText',
    },
    Hot: {
      imgUrl: `${configImagesUrl}/drink-finder/hot_drink.png`,
      imgWidth: 85,
      optionMessageId: 'hotDrinkOptionText',
    },
  };

  useEffect(() => {
    setLoading(true);
    fetchInitialDrinkFinderQuestion()
      .then((results) => {
        setQuestion(results?.nextQuestions?.[0]?.question);
        setQuestionId(results?.nextQuestions?.[0]?.questionId);
        setOptions([...(results?.nextQuestions?.[0]?.options ?? [])]);
        setSessionId(results?.dfSessionId);
      })
      .catch(() => setFetchError(true))
      .finally(() => setLoading(false));
  }, []);

  return question && !fetchError && !loading ? (
    <Animator animation="fade">
      <Card containerClasses="mb4" id="drink-finder-stream">
        <div className="flex">
          <Heading className="m3 text-bold" size="lg" tagName="h2">
            <FormattedMessage {...messages.answerQuestions} />
          </Heading>
        </div>
        <Rule className="mx3" weight="thin" />
        <div className="mb3">
          <p className="m3" size="sm">
            {question}
          </p>
          <div className="mx3">
            {options.map(({ option, optionId }, i) => {
              const queryString = qs.stringify({
                sessionId,
                source: 'stream',
                [questionId]: `${option}.${optionId}`,
              });
              return (
                <Link
                  className={`py4 px3 mb3 text-sm relative flex justify-between text-noUnderline ${styles.option}`}
                  key={i}
                  to={`/drink-finder?${queryString}`}
                >
                  <div>
                    <span className="text-bold">{option}</span>
                    <div>
                      <FormattedMessage
                        {...messages[
                          streamCardQuestionMap[option]?.optionMessageId
                        ]}
                      />
                    </div>
                  </div>
                  <img
                    alt=""
                    className={classNames('absolute', {
                      [styles.hotDrinkImage]: option.toLowerCase() === 'hot',
                      [styles.icedDrinkImage]: option.toLowerCase() === 'iced',
                    })}
                    role="presentation"
                    src={streamCardQuestionMap[option]?.imgUrl}
                    width={`${streamCardQuestionMap[option]?.imgWidth}`}
                  />
                </Link>
              );
            })}
          </div>
        </div>
      </Card>
    </Animator>
  ) : null;
};

const select = (state) => ({
  enableDrinkFinder: configSelector(state).enableDrinkFinder,
  configImagesUrl: configImagesUrlSelector(state),
});

const actions = {
  fetchInitialDrinkFinderQuestion: fetchDrinkFinder,
};

export default connect(select, actions)(DrinkFinderStreamCard);
