import createSingleResourceReducer from 'shared/app/stale-reducers/single-resource-reducer';

import {
  FETCH_DRINK_FINDER_RESET,
  FETCH_DRINK_FINDER,
  FETCH_DRINK_FINDER_SUCCESS,
  FETCH_DRINK_FINDER_ERROR,
  FETCH_DRINK_FINDER_UPDATE_BREADCRUMBS,
  UPDATE_DRINK_FINDER,
} from './actions';

const { initialState, reducer } = createSingleResourceReducer({
  startAction: FETCH_DRINK_FINDER,
});

export default (state = initialState, action) => {
  const updatedState = reducer(state, action);

  switch (action.type) {
    case FETCH_DRINK_FINDER_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          items: action.payload.items,
        },
        isLoading: false,
      };
    }
    case FETCH_DRINK_FINDER: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_DRINK_FINDER_UPDATE_BREADCRUMBS: {
      return {
        ...state,
        data: {
          ...state.data,
          breadcrumbs: action.payload,
        },
      };
    }
    case FETCH_DRINK_FINDER_RESET: {
      return {
        data: {},
        isLoading: true,
      };
    }
    case FETCH_DRINK_FINDER_ERROR: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        isLoading: false,
      };
    }
    case UPDATE_DRINK_FINDER: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          items: action.payload.items,
        },
      };
    }
  }
  return updatedState;
};
