import { defineMessages } from 'react-intl';

export const callsToAction = defineMessages({
  tryAgain: {
    id: 'ordering.notifications.tryAgain',
    defaultMessage: 'Try again',
    description:
      'Call to action asking the user to try the previous action again.',
  },
  chooseStore: {
    id: 'ordering.notifications.chooseStore',
    defaultMessage: 'Choose store',
    description: 'Call to action asking the user to choose a different store.',
  },
  chooseItem: {
    id: 'ordering.notifications.chooseItem',
    defaultMessage: 'Choose item',
    description: 'Call to action asking the user to choose a different item.',
  },
});

export const messages = defineMessages({
  rewardNotApplied: {
    id: 'ordering.notifications.rewardNotApplied',
    defaultMessage: "The Reward cannot be applied to what's in your cart",
    description:
      'Notification message displayed when user tries to redeem rewards but has no items in cart that are applicable',
  },
});
