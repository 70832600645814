import React from 'react';

import { useSelector } from 'react-redux';

import { isMobileViewportSelector } from 'shared/app/bundles/viewport';

import LocatorControls from '../locator-controls';
import LocationCardList from '../location-card-list';

export const StoreLocatorContentCrate = () => {
  const isMobileViewport = useSelector(isMobileViewportSelector);
  return (
    <React.Fragment>
      {!isMobileViewport && <LocatorControls />}
      <LocationCardList />
    </React.Fragment>
  );
};

export default StoreLocatorContentCrate;
