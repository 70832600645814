import { defineMessages } from 'react-intl';

export const defaultMessages = defineMessages({
  morning: {
    id: 'stream.greeting.defaultMorningGreeting',
    description:
      'Welcome message on user dashboard during morning (before 12pm).',
    defaultMessage:
      'Good morning{hasName, select, true {, {firstName}} other {}}',
  },
  afternoon: {
    id: 'stream.greeting.defaultAfternoonGreeting',
    description:
      'Welcome message on user dashboard during afternoon (between 12pm and 5pm).',
    defaultMessage:
      'Good afternoon{hasName, select, true {, {firstName}} other {}}',
  },
  evening: {
    id: 'stream.greeting.defaultEveningGreeting',
    description:
      'Welcome message on user dashboard during evening (after 5pm).',
    defaultMessage:
      'Good evening{hasName, select, true {, {firstName}} other {}}',
  },
});

export const autumnOneMessages = defineMessages({
  morning: {
    id: 'stream.greeting.autumnOneMorningGreeting',
    description:
      'Welcome message on user dashboard during morning (before 12pm) during autumn 1 season.',
    defaultMessage:
      'Fall vibes ahead{hasName, select, true {, {firstName}} other {}}',
  },
  afternoon: {
    id: 'stream.greeting.autumnOneAfternoonGreeting',
    description:
      'Welcome message on user dashboard during afternoon (between 12pm and 5pm) during autumn 1 season.',
    defaultMessage:
      'Fuel your fall{hasName, select, true {, {firstName}} other {}}',
  },
  evening: {
    id: 'stream.greeting.autumnOneEveningGreeting',
    description:
      'Welcome message on user dashboard during evening (after 5pm) during autumn 1 season.',
    defaultMessage:
      'Fall it up{hasName, select, true {, {firstName}} other {}}',
  },
});

export const autumnTwoMessages = defineMessages({
  morning: {
    id: 'stream.greeting.autumnTwoMorningGreeting',
    description:
      'Welcome message on user dashboard during morning (before 12pm) during autumn 2 season.',
    defaultMessage:
      'Fall vibes ahead{hasName, select, true {, {firstName}} other {}}',
  },
  afternoon: {
    id: 'stream.greeting.autumnTwoAfternoonGreeting',
    description:
      'Welcome message on user dashboard during afternoon (between 12pm and 5pm) during autumn 2 season.',
    defaultMessage:
      'Fuel your fall{hasName, select, true {, {firstName}} other {}}',
  },
  evening: {
    id: 'stream.greeting.autumnTwoEveningGreeting',
    description:
      'Welcome message on user dashboard during evening (after 5pm) during autumn 2 season.',
    defaultMessage: 'Stay cozy{hasName, select, true {, {firstName}} other {}}',
  },
});

export const holidayMessages = defineMessages({
  morning: {
    id: 'stream.greeting.holidayMorningGreeting',
    description:
      'Welcome message on user dashboard during morning (before 12pm) during Holiday season.',
    defaultMessage:
      'Festive faves await{hasName, select, true {, {firstName}} other {}}',
  },
  afternoon: {
    id: 'stream.greeting.holidayAfternoonGreeting',
    description:
      'Welcome message on user dashboard during afternoon (between 12pm and 5pm) during Holiday season.',
    defaultMessage:
      'Share the joy{hasName, select, true {, {firstName}} other {}}',
  },
  evening: {
    id: 'stream.greeting.holidayEveningGreeting',
    description:
      'Welcome message on user dashboard during evening (after 5pm) during Holiday season.',
    defaultMessage:
      'Merrier with you{hasName, select, true {, {firstName}} other {}}',
  },
});

export const springMessages = defineMessages({
  morning: {
    id: 'stream.greeting.springMorningGreeting',
    description:
      'Welcome message on user dashboard during morning (before 12pm) during Spring season.',
    defaultMessage:
      'Spring on the day{hasName, select, true {, {firstName}} other {}}',
  },
  afternoon: {
    id: 'stream.greeting.springAfternoonGreeting',
    description:
      'Welcome message on user dashboard during afternoon (between 12pm and 5pm) during Spring season.',
    defaultMessage:
      'Keep blooming{hasName, select, true {, {firstName}} other {}}',
  },
  evening: {
    id: 'stream.greeting.springEveningGreeting',
    description:
      'Welcome message on user dashboard during evening (after 5pm) during Spring season.',
    defaultMessage:
      "Today's your day{hasName, select, true {, {firstName}} other {}}",
  },
});

export const summerOneMessages = defineMessages({
  morning: {
    id: 'stream.greeting.summerOneMorningGreeting',
    description:
      'Welcome message on user dashboard during morning (before 12pm) during Summer season.',
    defaultMessage:
      'We’re on summertime{hasName, select, true {, {firstName}} other {}}',
  },
  afternoon: {
    id: 'stream.greeting.summerOneAfternoonGreeting',
    description:
      'Welcome message on user dashboard during afternoon (between 12pm and 5pm) during Summer season.',
    defaultMessage:
      'Sip into summer{hasName, select, true {, {firstName}} other {}}',
  },
  evening: {
    id: 'stream.greeting.summerOneEveningGreeting',
    description:
      'Welcome message on user dashboard during evening (after 5pm) during Summer season.',
    defaultMessage:
      'Hey{hasName, select, true { {firstName},} other {,}} summer’s on!',
  },
});

export const summerTwoMessages = defineMessages({
  morning: {
    id: 'stream.greeting.summerTwoMorningGreeting',
    description:
      'Welcome message on user dashboard during morning (before 12pm) during Summer season.',
    defaultMessage: 'Summer on{hasName, select, true {, {firstName}} other {}}',
  },
  afternoon: {
    id: 'stream.greeting.summerTwoAfternoonGreeting',
    description:
      'Welcome message on user dashboard during afternoon (between 12pm and 5pm) during Summer season.',
    defaultMessage:
      'Seize the yay{hasName, select, true {, {firstName}} other {}}',
  },
  evening: {
    id: 'stream.greeting.summerTwoEveningGreeting',
    description:
      'Welcome message on user dashboard during evening (after 5pm) during Summer season.',
    defaultMessage:
      'Turn up the cool down{hasName, select, true {, {firstName}} other {}}',
  },
});
